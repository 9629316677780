






















import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store/modules/employees";
import { IEmployee, ActionType } from "@/store/modules/employees";

@Component
export default class ActionHistory extends Vue {
  @Prop({ required: false, type: Number, default: ActionType.NONE }) private readonly actionType!: ActionType;

  store = getModule(store, this.$store);

  get filtertedEmployees() {
    return this.store.filtertedActionHistory(this.actionType);
  }

  get error(): string {
    return this.$store.state.employees.error;
  }

  getActionType(actionType: ActionType): string {
    if (actionType === ActionType.RESTORE) {
      return "Восстановили";
    }

    if (actionType === ActionType.DISMISS) {
      return "Уволили";
    }

    return "";
  }
}
